<template>
  <div class="order-list-wrap">
    <div class="order-current-list">
      <order-merge-item
        :item="currentOrder"
        :border="false"
        :show-current-order-tag="true"
        :language="language"
      />
    </div>

    <div
      v-if="data.length > 0"
      class="order-merge-tips"
    >
      {{ tabType === 1 ? language.SHEIN_KEY_PWA_26594 : language.SHEIN_KEY_PWA_26595 }}
    </div>

    <div class="order-merge-list">
      <div
        v-for="(item, index) in data"
        :key="index"
        class="order-merge-item-wrap"
      >
        <order-merge-item
          v-for="(child, childIndex) in item"
          :key="childIndex"
          :item="child"
          :border="index !== 0 && childIndex === 0"
          :show-time="childIndex === 0"
          :show-one-click-pay-tag="!!child.showOneClickPayTag"
          :language="language"
        />
      </div>
    </div>
  </div>
</template>

<script>
import OrderMergeItem from './OrderMergeItem.vue'

export default {
  name: 'OrderList',
  components: {
    OrderMergeItem
  },
  props: {
    language: {
      type: Object,
      default: () => {}
    },
    currentOrder: {
      type: Object,
      default: () => {}
    },
    data: {
      type: Array,
      default: () => []
    },
    // 1-强制修改订单 2-非强制修改订单
    tabType: {
      type: Number,
      default: 2
    }
  }
}
</script>
<style lang="less" scoped>
.order-merge-tips {
  position: sticky;
  top: 0;
  z-index: 100;
  font-family: 'SF UI Text';
  font-size: 12px;
  color: @sui_color_gray_dark3;
  background-color: @sui_color_gray_weak2;
  padding: 16/75rem 24/75rem;
  margin-top: 20/75rem;
}
</style>
