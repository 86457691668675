<template>
  <s-drawer
    type="full"
    direction="rtl"
    class="order-merge-drawer"
    :append-to-body="true"
    :show-close="false"
    :visible="visible"
    @update:visible="updateVisible"
    @opened="exposeOrderMergeDrawer"
  >
    <template slot="top">
      <s-title-nav
        :title="language.SHEIN_KEY_PWA_26591"
        :border="false"
      >
        <template slot="preblock">
          <s-title-nav-item
            icon-class="iconfont-s icons-nav_back"
            @click.native="updateVisible(false)"
          />
        </template>
      </s-title-nav>
    </template>
    <div class="order-merge-drawer__body">
      <tab-container
        v-if="showTab"
        :language="language"
        :order-merge-list="orderMergeList"
        :order-wrap-list="orderWrapList"
        :current-order="batchModifyAddress.current_billno"
      />
      <order-list
        v-else
        :language="language"
        :data="orderMergeList.length ? orderMergeList : orderWrapList"
        :tab-type="orderMergeList.length ? 1 : 2"
        :current-order="batchModifyAddress.current_billno"
      />
    </div>
  </s-drawer>
</template>

<script>
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { Drawer, TitleNav, TitleNavItem } from '@shein/sui-mobile'
import TabContainer from './TabContainer.vue'
import OrderList from './OrderList.vue'

export default {
  name: 'OrderMergeDrawer',
  components: {
    TabContainer,
    OrderList,
    SDrawer: Drawer,
    STitleNav: TitleNav,
    STitleNavItem: TitleNavItem,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    language: {
      type: Object,
      default: () => {},
    },
    batchModifyAddress: {
      type: Object,
      default: () => {},
    },
    billno: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      prevOrderNo: '',
    }
  },
  computed: {
    showTab() {
      const mergeList = this.getArrayValue(this.batchModifyAddress.merge_billno_list)
      const relationList = this.getArrayValue(this.batchModifyAddress.relation_billno_list)
      const wrapList = this.getArrayValue(this.batchModifyAddress.wrap_billno_list)
      if ((mergeList.length || relationList.length) && wrapList.length) {
        return true
      }
      return false
    },
    // 强制批量修改订单集合
    orderMergeList() {
      const mergeList = this.getArrayValue(this.batchModifyAddress.merge_billno_list)
      const relationList = this.getArrayValue(this.batchModifyAddress.relation_billno_list)
      mergeList.forEach((item) => {
        item.showOneClickPayTag = true
      })
      const list = [...mergeList, ...relationList]
      return this.mergeOrderListHandler(list)
    },
    // 非强制修改订单集合
    orderWrapList() {
      const list = this.getArrayValue(this.batchModifyAddress.wrap_billno_list)
      return this.mergeOrderListHandler(list)
    },
  },
  methods: {
    getArrayValue(value) {
      return Array.isArray(value) ? value : []
    },
    //根据add_time聚合订单
    mergeOrderListHandler(list = []) {
      const map = {}
      list.forEach((item) => {
        if (item.add_time) {
          const currentItem = map[item.add_time]
          if (currentItem) {
            currentItem.push(item)
          } else {
            map[item.add_time] = [item]
          }
        }
      })
      return Object.values(map)
    },
    updateVisible(event) {
      this.$emit('update:visible', event)
    },
    exposeOrderMergeDrawer() {
      if (this.prevOrderNo !== this.billno) {
        this.prevOrderNo = this.billno
        daEventCenter.triggerNotice({
          daId: '1-20-1-81',
          extraData: {
            order_no: this.billno,
          },
        })
      }
    },
  },
}
</script>
<style lang="less">
.order-merge-drawer {
  .S-drawer__header {
    border: none;
  }

  &__body {
    position: relative;
    height: 100%;
    background-color: @sui_color_gray_weak2;
  }
}
</style>
